'use client'

import { useEffect } from 'react'
import { Button, Cell, Icon, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import { useIntl } from 'react-intl'
import {
  EyeMagnifyingGlass24,
  SortingCenter24,
  VerifiedCheck24,
  InfoCircle24,
  X24,
} from '@vinted/monochrome-icons'
import { ElectronicsBadgeMultichrome64 } from '@vinted/multichrome-icons'

import SeparatedList from 'components/SeparatedList'

import { viewEvent } from '_libs/common/event-tracker/events'
import { formatCurrencyAmount } from '_libs/utils/formatString'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { CurrencyAmountModel } from 'types/models'

import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

type Props = {
  show: boolean
  onClose: () => void
  verificationFee?: CurrencyAmountModel
  screen: Screen
}

const ElectronicsVerificationBuyerModal = ({ verificationFee, show, onClose, screen }: Props) => {
  const translate = useTranslate('item.electronics_verification_modal.buyer')
  const { locale } = useIntl()
  const { track } = useTracking()

  useEffect(() => {
    if (!show) return

    track(viewEvent({ screen, target: ViewableElement.ElectronicsVerificationBuyerModal }))
  }, [show, track, screen])

  const contentRows: Array<{
    translationId: string
    iconName?: ComponentProps<typeof Icon>['name']
  }> = [
    { translationId: 'verification_hub', iconName: SortingCenter24 },
    { translationId: 'specialists_check', iconName: EyeMagnifyingGlass24 },
    { translationId: 'sending', iconName: VerifiedCheck24 },
    { translationId: 'refund', iconName: InfoCircle24 },
    { translationId: 'what_is_included' },
    { translationId: 'duration' },
  ]

  const renderContentRow = (
    translationId: string,
    iconName: ComponentProps<typeof Icon>['name'] | null = null,
  ) => {
    return (
      <Cell
        key={translationId}
        styling={Cell.Styling.Tight}
        prefix={
          iconName && (
            <Icon
              name={iconName}
              testId={`content-icon-${iconName.Title}`}
              aria={{
                'aria-hidden': 'true',
              }}
              color={Icon.Color.GreyscaleLevel4}
            />
          )
        }
        title={
          <Text
            as="h2"
            text={translate(`${translationId}_title`)}
            type={Text.Type.Title}
            testId={`content-title-${translationId}`}
          />
        }
        body={
          <Text
            as="span"
            text={translate(`${translationId}_body`)}
            testId={`content-description-${translationId}`}
          />
        }
      />
    )
  }

  const formattedVerificationFee = verificationFee && formatCurrencyAmount(verificationFee, locale)

  return (
    <Dialog show={show} testId="electronics-verification-buyer-modal">
      <Navigation
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={onClose}
            icon={<Icon name={X24} testId="icon-x" />}
            inline
            testId="electronics-verification-modal-navigation-close-button"
            aria={{
              'aria-label': translate('a11y.actions.close'),
            }}
          />
        }
      />
      <div
        className="modal-contained-content u-ui-padding-horizontal-x2-large"
        data-testid="electronics-verification-modal-content"
      >
        <div className="u-text-center">
          <Icon
            name={ElectronicsBadgeMultichrome64}
            color={Icon.Color.Primary}
            aria={{
              'aria-hidden': 'true',
            }}
          />
          <Spacer size={Spacer.Size.Large} />
          <Text as="h1" text={translate('title')} type={Text.Type.Heading} />
          {verificationFee && (
            <Text
              as="span"
              text={translate('fee', { amount: formattedVerificationFee })}
              theme="inherit"
              type={Text.Type.Body}
              testId="electronics-verification-modal-fee-title"
              alignment={Text.Alignment.Center}
            />
          )}
        </div>
        <div className="u-ui-padding-vertical-x-large">
          <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
            {contentRows.map(({ translationId, iconName }) =>
              renderContentRow(translationId, iconName),
            )}
            <Text
              as="span"
              text={translate('more')}
              html
              testId="electronics-verification-modal-learn-more"
            />
            <Text
              as="h3"
              text={translate('legal_disclaimer')}
              type={Text.Type.Subtitle}
              testId="electronics-verification-modal-legal-disclaimer"
            />
          </SeparatedList>
        </div>
      </div>
      <Cell>
        <Button
          text={translate('action.close')}
          styling={Button.Styling.Filled}
          onClick={onClose}
          testId="electronics-verification-modal-close-button"
          aria={{
            'aria-label': translate('a11y.actions.close'),
          }}
        />
      </Cell>
    </Dialog>
  )
}

export default ElectronicsVerificationBuyerModal
