'use client'

import { Button, Cell, Image, Text, Spacer, Dialog, Navigation, Icon } from '@vinted/web-ui'
import { Money24, Lock24, SpeechBubble24, X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useAsset from 'hooks/useAsset'

import {
  HELP_REFUND_POLICY,
  SERVICE_FEE_HELP_URL,
  SERVICE_FEE_PRO_HELP_URL,
} from 'constants/routes'
import { clickEvent } from '_libs/common/event-tracker/events'
import SeparatedList from 'components/SeparatedList'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

type Props = {
  isVisible: boolean
  sellerIsBusinessUser: boolean
  onClose: () => void
}

const BuyerProtectionModal = ({ isVisible, sellerIsBusinessUser, onClose }: Props) => {
  const { track } = useTracking()

  const asset = useAsset('assets/buyer-protection')

  const translationsPrefix = sellerIsBusinessUser
    ? 'checkout.buyer_protection_info_business'
    : 'checkout.buyer_protection_info'

  const translate = useTranslate(translationsPrefix)

  const getImageName = sellerIsBusinessUser
    ? 'buyer-protection-pro-shield.svg'
    : 'buyer-protection-shield.svg'

  const shieldImageUrl = asset(getImageName)

  const handleClose = () => {
    track(
      clickEvent({
        screen: Screen.EscrowFeeEducationModal,
        target: ClickableElement.CloseScreen,
      }),
    )

    onClose()
  }

  const handleRefundPolicyClick = () => {
    track(
      clickEvent({
        screen: Screen.EscrowFeeEducationModal,
        target: ClickableElement.RefundPolicyLink,
      }),
    )
  }

  const renderContentRow = (
    translationId: string,
    iconName: ComponentProps<typeof Icon>['name'] | null = null,
    translationValue?: Record<string, any>,
  ) => (
    <Cell
      key={translationId}
      styling={Cell.Styling.Tight}
      prefix={
        iconName && (
          <Icon
            name={iconName}
            testId={`content-icon-${iconName.Title}`}
            color={Icon.Color.Primary}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        )
      }
      title={
        <Text
          as="h2"
          text={translate(`${translationId}.title`)}
          type={Text.Type.Title}
          testId={`content-title-${translationId}`}
        />
      }
      body={
        <div className="buyer-protection-modal">
          <Text
            as="span"
            text={translate(`${translationId}.description`, translationValue)}
            html
            testId={`content-description-${translationId}`}
          />
        </div>
      }
    />
  )

  const refundPolicyLink = {
    'refund-policy-link': (chunks: string) => (
      // Disabling: to maintain the same referral policy as if it was hardcoded relative link
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={HELP_REFUND_POLICY}
        target="_blank"
        onClick={handleRefundPolicyClick}
        data-testid="refund-policy-link"
      >
        {chunks}
      </a>
    ),
  }

  const contentRows = () => [
    renderContentRow('refund_updated', Money24, refundPolicyLink),
    renderContentRow('secure_payments_updated', Lock24),
    renderContentRow('full_support_updated', SpeechBubble24),
  ]

  const proContentRows = () => [
    renderContentRow('money_back', Money24),
    renderContentRow('secure_payments', Lock24),
    renderContentRow('full_support', SpeechBubble24),
  ]

  function renderContent() {
    const serviceFeeHelpUrl = sellerIsBusinessUser ? SERVICE_FEE_PRO_HELP_URL : SERVICE_FEE_HELP_URL

    return (
      <>
        <div className="u-text-center">
          <Image src={shieldImageUrl} size={Image.Size.XLarge} alt="" />
          <Spacer size={Spacer.Size.Large} />
          <Text as="h1" text={translate('title')} type={Text.Type.Heading} />
          <a
            href={serviceFeeHelpUrl}
            target="_blank"
            rel="noreferrer"
            data-testid="service-fee-help-link"
          >
            <Text
              text={translate('service_fee_link_title')}
              clickable
              alignment={Text.Alignment.Center}
              type={Text.Type.Subtitle}
              as="p"
            />
          </a>
        </div>
        <div className="u-ui-padding-vertical-x-large">
          <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
            {sellerIsBusinessUser ? proContentRows() : contentRows()}
          </SeparatedList>
        </div>
      </>
    )
  }

  return (
    <Dialog show={isVisible} testId="service-fee-modal">
      <Navigation
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={handleClose}
            icon={<Icon name={X24} testId="icon-x" />}
            inline
            testId="service-fee-modal-navigation-close"
          />
        }
      />
      <div
        className="modal-contained-content u-ui-padding-horizontal-x2-large"
        data-testid="service-fee-modal-content"
      >
        {renderContent()}
      </div>
      <Cell>
        <Button
          text={translate('got_it')}
          styling={Button.Styling.Filled}
          onClick={handleClose}
          testId="service-fee-modal-close"
        />
      </Cell>
    </Dialog>
  )
}

export default BuyerProtectionModal
